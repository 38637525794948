import React, { useEffect, useState } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import parse from "html-react-parser";
import { customerView, CountryRegions, orderHistory, orderPlaced, refund, transactionRedeem, voucherPurchase } from "../../services/services";
import { getDate, alertModal } from "../../utils/helper";
import Loader from "../partial/Loader";
import { giftCard, voucher, backArrow } from "../../Image/Index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../partial/TextValidator";
import { BarCode, Internal_Token, sendEmailData, Token_Generator } from "../../services/auth";
import swal from "sweetalert";
import moment from "moment";

const RedeemCard = ({ detail, type, setShowDetail }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({
        walletsDetail: {},
    });
    const name = localStorage.getItem("name");
    const surname = localStorage.getItem("surname");
    const firstNameData = localStorage.getItem("firstName");
    const [loader, setLoader] = useState(false);
    const [redeemState, setRedeemState] = useState({
        currencySymbol: "",
        country: "",
        redeemPoints: null,
        amount: "",
    });
    const { currencySymbol, country, redeemPoints, amount } = redeemState;

    // useEffect(() => {
    //     const countryRegion = async () => {
    //         setLoader(true);
    //         const token = JSON.parse(localStorage.getItem("token_gen"));
    //         const response = await CountryRegions(token.access_token);
    //         let country = {};
    //         // eslint-disable-next-line
    //         response?.filter((item) => {
    //             if (item?.name === detail?.countries[0]?.code) {
    //                 country = {
    //                     symbol: getSymbolFromCurrency(item.isoCurrencySymbol),
    //                     name: item.name,
    //                 };
    //                 return country;
    //             }
    //         });
    //         setRedeemState((prev) => ({
    //             ...prev,
    //             currencySymbol: country.symbol,
    //             country: country.name,
    //         }));
    //         // setLoader(false);
    //     };
    //     if (type === "giftCard") {
    //         countryRegion();
    //     }
    //     // eslint-disable-next-line
    // }, []);

    useEffect(() => {
        if (type === "giftCard") {
            let temp = 0;
            detail?.vendors?.map((ele) => {
                temp = ele?.denominations[0]?.fix_value;
                return temp;
            });
            setRedeemState((prev) => ({
                ...prev,
                redeemPoints: Number(temp),
            }));
        }
        // eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        const inputValue = e.target.value;
        if (/^\d*$/.test(inputValue)) {
            setRedeemState((prev) => ({
                ...prev,
                [e.target.name]: inputValue,
            }));
        }
    };

    const handleAmount = (value) => {
        setRedeemState((prev) => ({
            ...prev,
            amount: value * 10,
        }));
    };
    const handleAmountq = (value) => {
        setRedeemState((prev) => ({
            ...prev,
            amount: value,
        }));
    };
    const customerCode = localStorage.getItem("c_code");

    useEffect(() => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        customerView(token.access_token, customerCode).then((res) => {
            setState((prev) => ({
                ...prev,
                walletsDetail: res?.walletsDetail,
            }));
            setLoader(false);
        });
    }, []);

    const { walletsDetail } = state;

    const handleGiftCard = (detail) => {
        alertModal("center", "warning", "Are you sure?", "buttons", ["Cancel", "Confirm"], 5000).then((value) => {
            if (value === true) {
                redeemGiftCard(detail);
            }
        });

        const redeemGiftCard = async (detail) => {
            setLoader(true);
            const redeemBody = {
                customer: { customercode: customerCode },
                redeemDetail: { walletType: "point", values: Math.ceil(redeemPoints * (7.5 * 10)), transactionType: "redeem", transactionDate: getDate(new Date(), "YYYY-MM-DD") },
            };
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const redeemRes = await transactionRedeem(token.access_token, JSON.stringify(redeemBody));
            if (redeemRes?.message === "Request processed successfully.") {
                const orderBody = {
                    shipping_address: {
                        firstname: "",
                        lastname: "",
                        email: "",
                        mobile: "",
                        address_line1: "",
                        address_line2: "",
                        city: "",
                        state: "",
                        zip: "",
                    },
                    billing_address: {
                        firstname: "",
                        lastname: "",
                        email: "",
                        mobile: "",
                        address_line1: "",
                        address_line2: "",
                        city: "",
                        state: "",
                        zip: "",
                    },
                    novus_transaction_id: redeemRes?.data?.currentTransactionId,
                    razorpay_payment_id: "",
                    payment_type: "point",
                    user_code: customerCode,
                    order_address: {
                        firstname: "",
                        lastname: "",
                        email: "",
                        mobile: "",
                        address_line1: "",
                        address_line2: "",
                        city: "",
                        state: "",
                        zip: "",
                    },
                    amount_paid: "",
                    total_points_redeemed: redeemRes?.data?.redeemAmount,
                    client_id: "34fg54-6f44-4bd9-maritime-35646g-gnkjfd54kc-56hgt9",
                    is_post_login: "",
                    payment_info: "",
                    type: "Giftcard",
                    voucher_response: [],
                    vendor_giftcard_id: detail?.vendors[0]?.vendor_giftcard_id,
                    code: detail?.code,
                    image: detail?.image,
                    title: detail?.title,
                    amount: redeemRes?.data?.redeemAmount / (7.5 * 10),
                    currency: currencySymbol,
                    country: country,
                };

                const orderRes = await orderPlaced(orderBody);

                if (orderRes?.message.data?.item_list[0]?.status === 3) {
                    const token = JSON.parse(localStorage.getItem("token_gen"));

                    const tempEmail = {
                        subject: "Congratulations, Your Voucher is Ready  ",
                        sendgridApiKey: "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",
                        fromEmail: "rewards@maritimefinancial.com",
                        fromName: "Maritime Rewards ",
                        emails: [
                            {
                                email: localStorage.getItem("email"),

                                name: localStorage.getItem("name"),
                            },
                        ],
                        body: ` <style>     p {font-size: 14px;font-family: Arial,Helvetica,sans-serif} </style>
                                             <div style="text-align: center;">
<table border="0" cellpadding="5" cellspacing="5" align="center"
             style="width: 600px;padding: 20px 25px 0 25px;  font-family: Arial,Helvetica,sans-serif">
<tbody>
<tr>
<td style="width:100%" style="padding:5px">
<img src="https://martimevoucheraccount.blob.core.windows.net/images/emailimg.png" alt="logo"
                           style="width: 100%; border-radius:20px 20px 0 0;">
</td>
</tr>
<tr>
<td style="text-align:left;padding:5px">
<br>
<h4>Hi ${firstNameData ? firstNameData : name},</h4>
<p style="font-family:Arial,Helvetica,sans-serif">
                          Great news!
</p>
<p>You’ve just claimed your Maritime Rewards Points. See voucher details below:</p>
<table width="580" border="0" cellspacing="0" cellpadding="0" align="center" bgcolor="#ffffff" style="margin: 0 auto; width: 580px; background: #fff;font-family:Arial,Helvetica,sans-serif;font-size:14px;">
<tr>
<td rowspan="4"><img width="200" src="${orderRes?.message?.data?.item_list[0]?.image}" /></td>
<td style="vertical-align:top;padding:5px">Voucher Name: </td>
<td style="vertical-align:top;padding:5px">${orderBody?.title}</td>
</tr>
<tr><td style="vertical-align:top;padding:5px">Value: </td><td style="vertical-align:top;text-align:left;"> ${orderBody?.amount} </td></tr>
<tr><td style="vertical-align:top;padding:5px">Voucher Code: </td> <td style="vertical-align:top;text-align:left;"> ${orderBody?.code}</td></tr>
<tr><td style="vertical-align:top;padding:5px">Expiry Date: </td><td style="vertical-align:top;text-align:left;">01-01-2025</td></tr>
</table>
<br><br>
 
                      <p style="font-weight: 700;">Terms & Conditions: </p>
<p>Gift cards are non-exchangeable, non-refundable, and non-cancellable once purchased. </p>
<p>${detail?.description}</p>
<p>Thank you for your continued support!</p>
<p>
  Best Regards, <br>
  Your Maritime Family
</p>
</td>
</tr>
</tbody>
</table>
</div>`,
                        purpose: "",
                        fileName: "",
                    };

                    sendEmailData(token.access_token, tempEmail);
                    // const customerRes = await customerView(token.access_token, customerCode);
                    // const customerRes = [];
                    // const orderList = await orderHistory(customerCode, "34fg54-6f44-4bd9-maritime-35646g-gnkjfd54kc-56hgt9");
                    // setState((prev) => ({
                    //     ...prev,
                    //     walletsDetail: customerRes?.walletsDetail,
                    //     orderList: orderList?.data,
                    // }));
                    swal({
                        position: "center",
                        icon: "success",
                        title: "Please check your email for the voucher code",
                        // timer: 2000,
                        button: "OK",
                    }).then(function () {
                        navigate("/");
                    });
                    // alertModal("center", "success", orderRes.message.message, "button", "OK", 5000);
                } else {
                    swal({
                        position: "center",
                        icon: "error",
                        title: "Order failed , Try again ",
                        // timer: 2000,
                        button: "OK",
                    }).then(function () {
                        navigate("/");
                    });
                }
            } else {
                alertModal("center", "error", "V", "button", "OK", 5000);
            }
            setLoader(false);
        };
    };

    const generateToken = async (merchantId) => {
        let body;
        if (merchantId === "MER001149") {
            body = { clientid: "maritimegeneral638495593242393813", clientsecret: "BUNDKNLNOR", grant_type: "client_credentials" };
        } else if (merchantId === "MER001150") {
            body = { clientid: "maritimelife638495593514066625", clientsecret: "SUGWQJLKMX", grant_type: "client_credentials" };
        } else if (merchantId === "MER001151") {
            body = { clientid: "foodgiant638495593776628350", clientsecret: "UWHDJTVTJB", grant_type: "client_credentials" };
        } else if (merchantId === "MER001152") {
            body = { clientid: "caribbeanmicrofinance638495594137929920", clientsecret: "UFNOFQCHSP", grant_type: "client_credentials" };
        } else if (merchantId === "MER001153") {
            body = { clientid: "fidelityfinance638495594637004692", clientsecret: "MSSVRYJBVD", grant_type: "client_credentials" };
        }
        const response = await Internal_Token(body);
        return response;
    };

    const handleVoucher = async (detail, value, fixPoint) => {
        console.log(detail)
        alertModal("center", "warning", "Are you sure?", "buttons", ["Cancel", "Confirm"], 5000).then((value) => {
            if (value === true) {
                purchaseVoucher(detail);
            }
        });
        const purchaseVoucher = async (detail) => {
            const customerCode = localStorage.getItem("c_code");
            setLoader(true);
            const redeemBody = {
                customer: { customercode: customerCode },
                redeemDetail: { walletType: "point", values: parseInt(value) * 10, transactionType: "redeem", transactionDate: getDate(new Date(), "YYYY-MM-DD") },
            };
            const merToken = await generateToken(detail.merchantCode[0]);
            console.log(merToken);
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const redeemRes = await transactionRedeem(merToken.access_token, JSON.stringify(redeemBody));
            if (redeemRes?.message === "Request processed successfully.") {
                const voucherBody = {
                    voucherTemplateCode: detail?.code,
                    merchantId: detail.merchantCode[0],
                    customerCode: customerCode,
                    value: parseInt(value * 10),
                    amount: parseInt(value),
                    RedeemTransactionId: redeemRes.data.currentTransactionId,
                };
                const voucherRes = await voucherPurchase(merToken.access_token, voucherBody);
                if (voucherRes?.message === "Request processed successfully.") {
                    const token = JSON.parse(localStorage.getItem("token_gen"));
                    const currentDate = moment().format("MMDD");
                    const voucherCode = `048${currentDate}${voucherRes?.data?.code}`
                    const response = await BarCode(token?.access_token, detail?.code === "VOT000241" ? voucherCode : voucherRes?.data?.code);
                    const barcodeImage = response?.url;

                    if (detail?.name?.toLowerCase() === "food giant"){
                        const tempEmail = {
                            subject: "Congratulations, Your Voucher is Ready  ",
                            sendgridApiKey: "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",
                            fromEmail: "rewards@maritimefinancial.com",
                            fromName: "Maritime Rewards ",
                            emails: [
                                {
                                    email: localStorage.getItem("email"),
                                    name: localStorage.getItem("name"),
                                },
                            ],
                            body: `<!DOCTYPE html>
                    <html lang="en">
                    
                    <head>
                    <meta charset="UTF-8">
                    <meta http-equiv="X-UA-Compatible" content="IE=edge">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Congratulations, Your Voucher is Ready</title>
                    </head>
                    
                    <body>
                    <style>
                        ol {
                            padding: 0 0 0 25px;
                        }
                    
                        ol li {
                            padding: 5px 0;
                            font-size: 14px;
                        }
                    
                        ul {
                            padding: 0 0 0 15px;
                        }
                    
                        ul li {
                            padding: 5px 0;
                            font-size: 14px;
                        }
                    
                        p {
                            font-size: 14px;
                        }
                    </style>
                    <table class="register" align="center" border="0" cellpadding="0" cellspacing="0"
                        style="background-color:#fff; border-radius:20px; font-family:arial,sans-serif; padding:15px 10px 30px 10px; width:100%">
                        <tbody>
                            <tr>
                                <td>
                                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="mainBody"
                                        style="width:600px">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                        style="background:#ffffff; border-radius:20px; line-height:1.5; padding:0 0 15px 0; width:100%">
                                                        <tbody>
                                                            <tr>
                                                                <td style="width:100%">
                                                                    <img src="https://martimevoucheraccount.blob.core.windows.net/images/emailimg.png" alt="logo"
                                                                        style="width: 100%; border-radius:20px 20px 0 0;">
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="text-align:center">
                                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                                        style="padding:20px 25px 0 25px; width:100%">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style="text-align:left">
                                                                                    <br>
                                                                                    <h4>Hi ${firstNameData ? firstNameData : name},</h4>
                                                                                    <p style="font-family:Arial,Helvetica,sans-serif">
                                                                                        Great news!</p>
                    
                                                                                    <p>You’ve just claimed your Maritime Rewards Points. See voucher details below:</p>
                     <table width="580" border="0" cellspacing="0" cellpadding="0" align="center" bgcolor="#ffffff" style="margin: 0 auto; width: 580px; background: #fff;ont-family:Arial,Helvetica,sans-serif;font-size:14px;">
                <tr><td style = "vertical-align:top;text-align:left";>Merchant: </td><td style = "vertical-align:top;text-align:left";>${voucherRes?.data?.voucherTemplateName}</td></tr>
                <tr><td style = "vertical-align:top;text-align:left";>Value: </td><td style = "vertical-align:top;text-align:left";> TT$ ${voucherRes?.data?.value} </td></tr>
                <tr><td style = "vertical-align:top;text-align:left";>Voucher Code: </td> <td style = "vertical-align:top;text-align:left";> ${detail?.code === "VOT000241" ? voucherCode.slice(-7) : voucherRes?.data?.code} <br> <img width='130'; height="130" src=${barcodeImage} alt="Voucher Barcode"/></td></tr>
    
                <tr><td style = "vertical-align:top;text-align:left";>Expiry Date: </td><td style = "vertical-align:top;text-align:left";> ${getDate(voucherRes?.data?.expiryDate, "DD-MM-YYYY")}</td></tr>
                </table>
                                                                                    <p style="font-weight: 700;">Terms & Conditions: </p>
                                                                                    <p>Gift cards are non-exchangeable, non-refundable, and non-cancellable once purchased. </p>
                    
                                                                                    <p>Thank you for your continued support!</p>
                    
                                                                                    <p>To redeem voucher:</p>
                    
                                                                                    <ol>
                                                                                        <li>Visit any Food Giant Supermarket location </li>
                                                                                        <li>Present this email at the front desk Monday – Friday during the hours of 8 am - 4 pm or at the cash register before checking out to activate your voucher.</li>
                                                                                        <li>The voucher will be applied to the total bill and you will be asked to remit the balance if bill exceeds voucher amount.</li>
                                                                                        <li>The voucher must be used in its entirety at the time of redemption. </li>
                                                                                    </ol>
                                                                                    <p>Important Notice: All internal voucher redemptions must be accompanied with one form of identification. Only the client can redeem their points for internal vouchers/ it cannot be gifted. </p>
                    
                    
                    
                                                                                    <p>Best Regards, <br>
                                                                                        Your Maritime Family
                                                                                    </p>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                    
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </body>
                    
                    </html>`,
                            purpose: "",
                            fileName: "",
                        };
                    
                        sendEmailData(token.access_token, tempEmail);
                    }
                    else {
                        const tempEmail = {
                            subject: "Congratulations, Your Voucher is Ready  ",
                            sendgridApiKey: "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",
                            fromEmail: "rewards@maritimefinancial.com",
                            fromName: "Maritime Rewards ",
                            emails: [
                                {
                                    email: localStorage.getItem("email"),
                                    name: localStorage.getItem("name"),
                                },
                            ],
                            body: `<!DOCTYPE html>
                <html lang="en">
    
                <head>
                    <meta charset="UTF-8">
                    <meta http-equiv="X-UA-Compatible" content="IE=edge">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Congratulations, Your Voucher is Ready</title>
                </head>
    
                <body>
                    <style>
                        ol {
                            padding: 0 0 0 25px;
                        }
    
                        ol li {
                            padding: 5px 0;
                            font-size: 14px;
                        }
    
                        ul {
                            padding: 0 0 0 15px;
                        }
    
                        ul li {
                            padding: 5px 0;
                            font-size: 14px;
                        }
    
                        p {
                            font-size: 14px;
                        }
                    </style>
                    <table class="register" align="center" border="0" cellpadding="0" cellspacing="0"
                        style="background-color:#fff; border-radius:20px; font-family:arial,sans-serif; padding:15px 10px 30px 10px; width:100%">
                        <tbody>
                            <tr>
                                <td>
                                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="mainBody"
                                        style="width:600px">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                        style="background:#ffffff; border-radius:20px; line-height:1.5; padding:0 0 15px 0; width:100%">
                                                        <tbody>
                                                            <tr>
                                                                <td style="width:100%">
                                                                    <img src="https://martimevoucheraccount.blob.core.windows.net/images/emailimg.png" alt="logo"
                                                                        style="width: 100%; border-radius:20px 20px 0 0;">
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="text-align:center">
                                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                                        style="padding:20px 25px 0 25px; width:100%">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style="text-align:left">
                                                                                    <br>
                                                                                    <h4>Hi ${firstNameData ? firstNameData : name},</h4>
                                                                                    <p style="font-family:Arial,Helvetica,sans-serif">
                                                                                        Great news!</p>
    
                                                                                    <p>You’ve just claimed your Maritime Rewards Points. See voucher details below:</p>
                <table width="580" border="0" cellspacing="0" cellpadding="0" align="center" bgcolor="#ffffff" style="margin: 0 auto; width: 580px; background: #fff;ont-family:Arial,Helvetica,sans-serif;font-size:14px;">
                <tr><td style = "vertical-align:top;text-align:left";>Merchant: </td><td style = "vertical-align:top;text-align:left";>${voucherRes?.data?.voucherTemplateName}</td></tr>
                <tr><td style = "vertical-align:top;text-align:left";>Value: </td><td style = "vertical-align:top;text-align:left";> TT$ ${voucherRes?.data?.value} </td></tr>
                <tr><td style = "vertical-align:top;text-align:left";>Voucher Code: </td> <td style = "vertical-align:top;text-align:left";> ${detail?.code === "VOT000241" ? voucherCode.slice(-7) : voucherRes?.data?.code} <br> <img width='130'; height="130" src=${barcodeImage} alt="Voucher Barcode"/></td></tr>
    
                <tr><td style = "vertical-align:top;text-align:left";>Expiry Date: </td><td style = "vertical-align:top;text-align:left";> ${getDate(voucherRes?.data?.expiryDate, "DD-MM-YYYY")}</td></tr>
                </table>
    
                                                                                    <p style="font-weight: 700;">Terms & Conditions: </p>
                                                                                    <p>Gift cards are non-exchangeable, non-refundable, and non-cancellable once purchased. </p>
    
                                                                                    <p>Thank you for your continued support!</p>
    
                                                                                    <p>Best Regards, <br>
                                                                                        Your Maritime Family
                                                                                    </p>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
    
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </body>
    
                </html>`,
                            purpose: "",
                            fileName: "",
                        };
    
                        sendEmailData(token.access_token, tempEmail);
                    }
                 
                    swal({
                        position: "center",
                        icon: "success",
                        title: "Please check your email for the voucher code",
                        // timer: 2000,
                        button: "OK",
                    }).then(function () {
                        navigate("/");
                    });
                    alertModal("center", "success", "Voucher purchase successfully", "button", "OK");
                    setLoader(false);
                } else {
                    let date = redeemRes?.data?.Wallets?.map((ele) => {
                        return ele?.transactionData?.find((item) => {
                            return item.transactionId === redeemRes.data.currentTransactionId;
                        });
                    });
                    const refundBody = {
                        customer: {
                            customercode: customerCode,
                        },
                        transactionId: redeemRes?.data?.currentTransactionId,
                        transactionAmount: 0,
                        transactionDate: getDate(date[0]?.transactionDate, "YYYY-MM-DD"),
                    };
                    const refundRes = await refund(merToken.access_token, refundBody);
                    const customerRes = await customerView(token.access_token, customerCode);
                    // const customerRes = [];

                    setState((prev) => ({
                        ...prev,
                        walletsDetail: customerRes?.walletsDetail,
                        activityLogs: customerRes?.activityLogs,
                    }));
                    if (refundRes?.message === "Request processed successfully.") {
                        alertModal("center", "error", "Voucher purchase failed", "button", "OK", 5000);
                    } else {
                        alertModal("center", "error", refundRes?.message, "button", "OK", 5000);
                    }
                    setLoader(false);
                }
            } else {
                alertModal("center", "error", redeemRes?.message, "button", "OK", 5000);
                setLoader(false);
            }
        };

    };

    const onSubmit = () => { };

    return (
        <>
            {loader && <Loader />}
            <div className="allCardContainer">
                <div className="function_row">
                    <div className="">
                        <Link
                            className="backAro"
                            state={{ type: location?.state?.selectedValue }}
                            onClick={() => {
                                setShowDetail((prev) => !prev);
                            }}
                        >
                            <img src={backArrow} alt="Back" height={16} />
                            Back
                        </Link>
                    </div>
                    <div className="points_box">
                        <div className="header_points_box">
                            <h4>
                                Your Points <img src={"/Images/star-silver.png"} /> <span>{walletsDetail?.availableValue}</span>
                            </h4>
                        </div>
                    </div>
                </div>
                {type === "giftCard" ? (
                    <div className=" d-flex justify-content-between giftCardDetail">
                        <div className="giftCardDetail__left p-5rem border rounded">
                            <img src={detail?.image ? detail?.image : giftCard} alt="" style={{ maxWidth: "600px", width: "100%" }} />
                        </div>
                        <div className="giftCardDetail__right">
                            <span className="card_denominations">
                                {detail?.vendors?.length > 0 &&
                                    detail?.vendors.map((ele) => {
                                        return ele.denominations.map((ele, i) => {
                                            return (
                                                <span key={i} className="card_denominations__btn">
                                                    <button
                                                        className="btn"
                                                        style={{ background: redeemPoints === Number(ele.fix_value) ? "#4545c7" : "#000000", color: "#ffffff" }}
                                                        onClick={() =>
                                                            setRedeemState((prev) => ({
                                                                ...prev,
                                                                redeemPoints: Number(ele.fix_value),
                                                            }))
                                                        }
                                                    >
                                                        <span className="" title={ele.fix_value}>
                                                            <span>$</span>
                                                            {ele.fix_value}
                                                        </span>
                                                    </button>
                                                </span>
                                            );
                                        });
                                    })}
                            </span>
                            <h5>{detail?.title}</h5>
                            <hr></hr>
                            <span>{parse(detail?.description)}</span>
                            <hr></hr>
                            {/* <div className="row"> */}
                            <button className="btn btn-primary col-sm-12 col-md-12 col-lg-12 btn-lg w-100" disabled={walletsDetail?.TotalavailableValue < redeemPoints * (7.5 * 10)} onClick={() => handleGiftCard(detail)}>
                                REDEEM FOR {Math.ceil((7.5 * 10) * redeemPoints)} POINTS
                            </button>
                            {/* </div> */}
                        </div>
                    </div>
                ) : (
                    <div className="giftCardDetail">
                        <div className="giftCardDetail__left border  rounded p-5rem">
                            <img src={detail?.imageUpload ? detail?.imageUpload : voucher} alt="fig" />
                        </div>
                        <div className="giftCardDetail__right">
                            <h4 className="lh-sm">
                                <span>{detail?.name}</span>
                            </h4>
                            <div className="gc_amount">{detail?.description}</div>
                            <hr></hr>
                            <ValidatorForm className="signUpPage__formSection" onSubmit={(e) => onSubmit(e)}>
                                {detail?.voucherValueType === "No Value" && detail?.voucherGeneratedType === 1 && !detail.voucherValue && (
                                    <>
                                        <span className="card_denominations">
                                            {Array.from([1, 2, 3, 4, 5], (x, i) => (
                                                <span className="card_denominations__btn" key={i}>
                                                    <button type="button" onClick={() => handleAmount(x)} disabled={x * 10 > walletsDetail?.TotalavailableValue}>
                                                        {x * 10}
                                                    </button>
                                                </span>
                                            ))}
                                        </span>
                                        <div className="my-3">
                                            <TextValidator className="form-control" name="amount" maxLength="3" value={amount} onChange={handleChange} validators={["required"]} errorMessages={["Amount is required."]} />
                                        </div>
                                    </>
                                )}
                                {detail?.voucherValueType === "No Value" && detail?.voucherGeneratedType === 0 && parseInt(detail.voucherValue) > 0 ? (
                                    <button type="button" className="btn btn-primary btn-lg w-100" disabled={walletsDetail?.TotalavailableValue < detail?.voucherValue || walletsDetail?.TotalavailableValue <= 0 || detail?.voucherValue <= 0} onClick={() => handleVoucher(detail, parseInt(detail?.voucherValue), "fix-point")}>
                                        REDEEM FOR {detail?.voucherValue} POINTS
                                    </button>
                                ) : detail?.voucherValueType === "No Value" && detail?.voucherGeneratedType === 1 && (detail.voucherValue === 0 || "0" || null) ? (
                                    <button type="button" className="btn btn-primary btn-lg w-100" disabled={walletsDetail?.TotalavailableValue < amount * 10 || walletsDetail?.TotalavailableValue <= 0 || amount * 10 <= 0} onClick={() => handleVoucher(detail, parseInt(amount))}>
                                        REDEEM FOR {amount * 10} POINTS
                                    </button>
                                ) : (
                                    <div>
                                        {/* <span className="card_denominations">
                                            {Array.from([detail?.voucherValue], (x, i) => (
                                                <span className="card_denominations__btn" key={i}>
                                                    <button type="button" onClick={() => handleAmount(x)} disabled={x * 10 > walletsDetail?.TotalavailableValue}>
                                                        {x}
                                                    </button>
                                                </span>
                                            ))}
                                           
                                        </span> */}


                                        {detail?.voucherValue?.split(',').map((x, i) => (
                                            <span className="card_denominations__btn" key={i}>
                                                <button
                                                    type="button"
                                                    onClick={() => handleAmountq(Number(x))}
                                                    disabled={Number(x) * 10 > walletsDetail?.TotalavailableValue}
                                                >
                                                    {x}
                                                </button>
                                            </span>
                                        ))}


                                        <div className="my-3">

                                        </div>

                                        <button type="button" className="btn btn-primary btn-lg w-100" disabled={walletsDetail?.TotalavailableValue < amount * 10 || walletsDetail?.TotalavailableValue <= 0 || amount * 10 <= 0} onClick={() => handleVoucher(detail, parseInt(amount))}>
                                            REDEEM FOR {amount * 10} POINTS
                                        </button>
                                    </div>
                                )}
                            </ValidatorForm>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default RedeemCard;